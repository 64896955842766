
.exam-apply {
  display: flex;
  padding: 110px 80px;
  justify-content: center;
  box-sizing: border-box;
  .exam-form {
    width: 900px;
    border: 1px solid #ccc;
    border-radius: 5px;
    .exam-from-title {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .exam-apply-main {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      .dialog-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 60px 0;
      }
    }
  }
}
